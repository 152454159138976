/* eslint-disable @typescript-eslint/no-explicit-any */
import http from "@/http";

class GeneroService {
  private endpoint = "generos";

  list(): Promise<any> {
    return http.get(`${this.endpoint}`);
  }
}

export default new GeneroService();
