
import Service from "@/services/conta/ContaService";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import { ref, defineComponent } from "vue";
import { requiredField } from "@/util/form-rules";

interface Form {
  password: string;
  passwordRepeat: string
}

const baseForm: Form = {
  password: "",
  passwordRepeat: "",
};

export default defineComponent({
  setup(props, { emit }) {
    const $q = useQuasar();
    const show = ref(false);
    const form = ref<Form>(baseForm);
    const loading = ref<boolean>(false);

    const open = (): void => {
      show.value = true;
    };

    const submit = (): void => {
      if (form.value.password === form.value.passwordRepeat) {
        loading.value = true;
        Service.senha(form.value)
          .then(() => {
            afterSubmit();
            showNotify({
              type: "positive",
              message: "Senha alterada com sucesso",
            });
          })
          .catch((err) => {
            console.log(err);
            showNotify({
              type: "negative",
              message: "Erro ao alterar senha",
            });
          })
          .finally(() => (loading.value = false));
      } else {
        loading.value = false;
        showNotify({ type: "warning", message: "Senhas não conferem" });
      }
    };

    const afterSubmit = (): void => {
      emit("update");
      form.value = baseForm;
      show.value = false;
    };

    const showNotify = (opts: any): void => {
      $q.notify(opts);
    };

    return {
      show,
      open,
      form,
      requiredField,
      loading,
      showNotify,
      submit,
      afterSubmit,
    };
  },
});
