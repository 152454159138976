
import { ref, defineComponent, onMounted } from "vue";
import { requiredField } from "@/util/form-rules";
import Service from "@/services/conta/ContaService";
import GeneroService from "@/services/admin/GeneroService";
import Genero from "@/interfaces/Genero";
import ImagemUpload from "@/components/admin/ImagemUpload.vue";
import AlterarSenha from "@/components/conta/AlterarSenha.vue";
import useQuasar from 'quasar/src/composables/use-quasar.js';;

interface Form {
  nome: string;
  email: string;
  telefone: string | null;
  data_nascimento: Date | null;
  biografia: string | null;
  genero_id: string;
}

const baseForm: Form = {
  nome: "",
  email: "",
  telefone: "",
  data_nascimento: null,
  biografia: "",
  genero_id: "",
};

export default defineComponent({
  components: { ImagemUpload, AlterarSenha },

  setup() {
    const id = ref<string>("");
    const url = ref<string>(process.env.VUE_APP_API_BASE);
    const urlImg = ref<string>(process.env.VUE_APP_STORAGE_BASE);
    const $q = useQuasar();
    const form = ref<Form>(baseForm);
    const generos = ref<Genero[]>([]);
    const loading = ref<boolean>(false);
    const modalUpload = ref();
    const modalSenha = ref();

    const openModalUpload = (id: string): void => {
      modalUpload.value.open(id);
    };
    const openModalSenha = (): void => {
      modalSenha.value.open();
    };

    const submit = (): void => {
      loading.value = true;
      Service.update(form.value)
        .then(() => {
          getUser();
          showNotify({
            type: "positive",
            message: "Dados salvos com sucesso",
          });
        })
        .catch((err) => {
          console.log(err);
          showNotify({
            type: "negative",
            message: "Erro ao salvar dados",
          });
        })
        .finally(() => (loading.value = false));
    };

    const getUser = (): void => {
      loading.value = true;
      Service.get()
        .then((res) => {
          form.value = res.data;
        })
        .catch((err) => {
          console.log(err);
          showNotify({
            type: "negative",
            message: "Erro ao buscar dados",
          });
        })
        .finally(() => (loading.value = false));
    };

    const getGeneros = (): void => {
      loading.value = true;
      GeneroService.list()
        .then((res) => {
          generos.value = res.data;
        })
        .catch((err) => {
          console.log(err);
          showNotify({
            type: "negative",
            message: "Erro ao buscar gêneros",
          });
        })
        .finally(() => (loading.value = false));
    };

    const showNotify = (opts: any): void => {
      $q.notify(opts);
    };

    onMounted(() => {
      getGeneros();
      getUser();
    });

    return {
      id,
      modalUpload,
      openModalUpload,
      modalSenha,
      openModalSenha,
      requiredField,
      loading,
      form,
      url,
      urlImg,
      generos,
      showNotify,
      submit,
      getUser,
    };
  },
});
